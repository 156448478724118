* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainBodyCard {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.container {
  margin: 15px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0rem;
  border-radius: 0.5rem;
  background-color: hsl(207, 4%, 75%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(207, 4%, 65%);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(207, 4%, 55%);
}

.card {
  width: 20em;
  margin-right: -1px;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.359) 0px 0px 0px 1.2px;
  padding: 2rem 2rem;
  perspective: 1000px;
  transition: 0.36s;
}

.card:hover {
  transform: translateY(-8px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.414);
  background-color: white;
}

.updata {
  padding-top: 2.4rem;
}

.header {
  position: relative;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  width: 9em;
  height: 9em;
  border-radius: 50%;
  margin: 0 auto;
}

img {
  width: 100%;
  transition: transform 0.3s ease;
}

.name {
  font-size: 28px;
  margin: 1.2em 0 0.5em;
  margin-bottom: 1.3rem;
  font-weight: 600;
  transition: transform 0.3s ease;
}

.shoe-size {
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s ease;
}

.shoe-size span {
  display: inline-block;
  background-color: #ddd;
  color: #2b2b2b;
  text-align: center;
  padding: 0.2em 1.2em;
  margin: 0.2rem;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  border-radius: 30px;
}

.shoe-size span:hover {
  background-color: #d4d4d4;
}

.shoe-size input {
  opacity: 0;
  position: absolute;
}

.shoe-size input:checked+span {
  background-color: #163c79;
  color: #fff;

  font-weight: 400;
}

.description {
  padding: 0.2rem 0;
  margin-top: 0.8em;
  font-weight: 400;
  transition: transform 0.3s ease;
}

.buy {
  margin-top: 1.5em;
  width: 100%;
  border: 0;
  padding: 0.6em 0;
  font-weight: 500;
  font-size: 16px;
  border-radius: 15px;
  background-color: #163c79;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
}

.buy:hover {
  background-color: #1c6ae8;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}